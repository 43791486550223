/**
 *
 * @param hex color string
 * @returns [r, g, b] color array
 */
export const hex2rgb = (hex: string) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return [r, g, b] as [number, number, number];
};

/**
 *
 * @param color [r, g, b]
 * @returns luminance of color
 */
export function calculateLuminance(color: [number, number, number]) {
  const [r, g, b] = color;
  return 0.299 * r + 0.587 * g + 0.114 * b;
}

/**
 *
 * @param color [r, g, b]
 * @returns [r, g, b] darkened if luminance is too high
 */
export function darkenIfTooLight(color: [number, number, number]) {
  const luminanceThreshold = 200; // Adjust this threshold as needed
  const darkeningFactor = 50; // Adjust this darkening factor as needed
  const luminance = calculateLuminance(color);
  if (luminance > luminanceThreshold) {
    const [r, g, b] = color;
    const darkenedColor = [
      Math.max(0, r - darkeningFactor),
      Math.max(0, g - darkeningFactor),
      Math.max(0, b - darkeningFactor),
    ];
    return darkenedColor;
  } else {
    return color;
  }
}

export function colorIsLight(color: [number, number, number]) {
  const luminanceThreshold = 200; // Adjust this threshold as needed
  const luminance = calculateLuminance(color);
  return luminance > luminanceThreshold;
}



export function getRandomColorHexa() {
  return "#000000".replace(/0/g, function () {
    return (~~(Math.random() * 16)).toString(16);
  });
}