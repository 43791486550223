
import { computed, defineComponent, PropType, ref } from "vue";
import BaseInput from "./BaseInput.vue";
import BIcon from "../BIcon.vue";
import { generateUUID } from "@/utils";
import { IconType, ShadowType, VariantType } from "../index";

export default defineComponent({
  name: "b-input-text",
  components: {
    BaseInput,
    BIcon,
  },
  props: {
    modelValue: {
      type: String,
      default: () => "",
    },
    forceLabelColor: String,
    label: String,
    counter: Boolean,
    error: String,
    variant: {
      type: String as PropType<VariantType>,
      default: () => "primary",
    },
    type: {
      type: String as PropType<
        "text" | "email" | "search" | "tel" | "url" | "password"
      >,
      default: () => "text",
    },
    shadow: {
      type: String as PropType<ShadowType>,
      default: () => "none",
    },
    outlined: {
      type: Boolean,
      default: () => true,
    },
    showFocus: {
      type: Boolean,
      default: () => true,
    },
    icon: String as PropType<IconType>,
    disabled: Boolean,
    block: Boolean,
    clearable: Boolean,
  },
  emits: ["update:modelValue"],
  setup(props, { attrs, emit }) {
    const inputRef = ref();
    const uuid = generateUUID();
    const name = computed<string>(() => (attrs.name as string) ?? uuid);

    const counterText = computed(() => {
      if (props.counter) {
        if (attrs.maxLength || attrs.maxlength) {
          return `${props.modelValue.length}/${
            attrs.maxLength || attrs.maxlength
          }`;
        } else {
          return `${props.modelValue.length}`;
        }
      }
      return false;
    });

    const updateValue = (event) => {
      emit("update:modelValue", event.target.value);
    };

    return {
      inputRef,
      name,
      counterText,
      updateValue,
    };
  },
});
