import { VuexModule, Module, Action, Mutation, getModule } from 'vuex-module-decorators'
import store from "@/pages/bureau/store";

export interface ISettingsState {
    name: string,
    showDrawer: boolean,
    showApiErrorMessage: boolean
}

@Module({ dynamic: true, store, name: "settings", namespaced: true })
class Settings extends VuexModule implements ISettingsState {
  public name = "";
  public showApiErrorMessage = true;
  public openDrawer = false;

  @Mutation
  private SET_NAME(name: string) {
    this.name = name;
  }

  @Mutation
  private SET_DRAWER(isVisible: boolean) {
    this.openDrawer = isVisible;
  }

  @Action
  public updateName(name: string) {
    this.SET_NAME(name);
  }

  @Action
  public toggleDrawer() {
    this.SET_DRAWER(!this.openDrawer);
  }

  @Action
  public closeDrawer() {
    this.SET_DRAWER(false);
  }

  get showDrawer() {
    return this.openDrawer;
  }
}

export const SettingsModule = getModule(Settings);