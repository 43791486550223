import { useAuthStore, UserType, useSocietesStore } from "@/stores";
import nprogress from "nprogress";

nprogress.configure({
  showSpinner: false,
  parent: "#app",
});

import router from "./router";

/**
 * Page qui ne necessite pas d'etre authentifié
 */
const whitelist = [
  "auth",
  "societe-select",
  "login",
  "forgot-password",
  "visitor",
  "test",
  "disable",
];

router.beforeEach(async (to, from, next) => {
  nprogress.start();
  nprogress.inc();

  const societeStore = useSocietesStore();
  const authStore = useAuthStore();

  if (
    to.name == "societe-select" ||
    to.name == "disable" ||
    (process.env.NODE_ENV != "production" && to.name == "test")
  )
    return next();


  // Si pas de index alors on redirige vers la page de sélection de societe
  if (!to.params.index_societe && societeStore.selected < 0) {
    return next({ name: "societe-select" });
  }

  // Si il y a deja un index dans le store mais pas l'url alors on le set dans l'URL
  else if (!to.params.index_societe || societeStore.selected >= 0) {
    to.params.index_societe = societeStore.selected.toString();
  }
  // Si on a un index dans l'url mais pas dans le store alors on le set
  else if (to.params.index_societe || societeStore.selected < 0) {
    societeStore.selected = parseInt(to.params.index_societe as string);
  }
  // On verifie qu'on a bien les prefs sinon on les recuperes
  if (
    !societeStore.active ||
    parseInt(to.params.index_societe as string) != societeStore.selected
  ) {
    await societeStore.selectSociete(
      parseInt(to.params.index_societe as string)
    );
  }

  //Si le service est déactivé
  if (!societeStore.active) {
    return next({
      name: "disable",
      params: {
        index_societe: to.params.index_societe,
      },
    });
  }

  // Etape 2: on vérifie le token
  if (
    !whitelist.includes(to.name as string) &&
    (!authStore.token || !(await authStore.verifAuthentification()))
  ) {
    nprogress.inc();
    return next({
      name: "login",
      params: {
        index_societe: to.params.index_societe,
      },
      query: {
        redirect: to.fullPath,
      },
    });
  }

  // Etape 3: On verifie les données de l'utilisateur
  if (
    authStore.user == null ||
    authStore.user.email != localStorage.getItem("login")
  ) {
    nprogress.inc();
    if (authStore.type == UserType.intervenant) {
      await authStore.fetchIntervenantUser();
    }
  }

  // Etape 4: On verife le droit d'acces
/*   if (!authStore.lastCheckAccess) {
    nprogress.inc();
    await authStore.fetchAccess();
  }

  if (  !whitelist.includes(to.name as string) && !authStore.canAccess(to)) {
    nprogress.inc();
    return next({
      name: "login",
      params: {
        index_societe: to.params.index_societe,
      },
      query: {
        redirect: to.fullPath,
      },
    });
  } */

  next();
});

router.afterEach(() => {
  nprogress.done();
});
