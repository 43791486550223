import { LayoutEnum } from "@/pages/bureau/composables/useLayout";
import { UserModule, UserRole } from "@/pages/bureau/store/modules/user.module";
import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import { IMeta, NavGroup } from "@/pages/bureau/composables/useNavigation";
import { ProjetsModule } from "@/pages/bureau/store/modules/projets.module";
import { IntervenantModule } from "@/pages/bureau/store/modules/intervenants.module";
import { AccessModule } from "@/pages/bureau/store/modules/access.module";
import accessTemp, { hasPathAccess } from "../store-temp/access.temp";
import { awaitData, isPreviewInUrl } from "@/utils";

export type Route = RouteRecordRaw & {
  meta?: IMeta;
};

export const homeRoute: Route = {
  name: "home",
  path: "/home",
  component: () => import("@/pages/bureau/views/home/Home.vue"),
  meta: {
    layout: {
      type: LayoutEnum.default,
      strict: true,
      showNavbarOnMobile: true,
    },
    roles: [UserRole.logged],
    navInfo: {
      order: 1,
      navGroup: NavGroup.home,
      i18nLabel: "navigation.home",
      icon: "preview",
    },
  },
};

export const previewRoute: Route = {
  name: "preview",
  path: "/preview",
  component: () => import("@/pages/bureau/views/Preview.vue"),
  meta: {
    layout: {
      type: LayoutEnum.empty,
      strict: true,
    },
  },
};

export const infosRoute: Route = {
  name: "icons",
  path: "/icons",
  component: () => import("@/pages/bureau/components/TestAllIcons.vue"),
  meta: {
    layout: {
      type: LayoutEnum.default,
      strict: true,
      showNavbarOnMobile: false,
    },
    navInfo: {
      order: 1,
      navGroup: NavGroup.home,
      i18nLabel: "navigation.home",
      icon: "preview",
    },
  },
};

export const expensesRoute: Route = {
  name: "expenses",
  path: "/expenses",
  component: () =>
    import("@/pages/bureau/views/expense-report/ExpenseReportBase.vue"),
  meta: {
    layout: {
      type: LayoutEnum.default,
      strict: true,
      showNavbarOnMobile: false,
      canModal: false,
    },
    roles: [UserRole.logged],
    navInfo: {
      order: 2,
      navGroup: NavGroup.perso,
    },
    pageInfo: {
      show: true,
      i18nTitle: "expense.expenses.title",
    },
  },
  children: [
    {
      name: "expense-new",
      path: "new",
      component: () =>
        import(
          "@/pages/bureau/views/expense-report/subviews/frais-new/FraisNew.vue"
        ),
      meta: {
        layout: {
          type: LayoutEnum.default,
          strict: true,
          showNavbarOnMobile: false,
          canModal: false,
        },
        roles: [UserRole.logged],
        pageInfo: {
          show: true,
          i18nTitle: "expense.expenseNew.title",
        },
        canModal: true,
      },
    },
    {
      name: "expense-edit",
      path: ":idFrais/edit",
      component: () =>
        import(
          "@/pages/bureau/views/expense-report/subviews/frais-edit/FraisEdit.vue"
        ),
      meta: {
        layout: {
          type: LayoutEnum.default,
          strict: true,
          showNavbarOnMobile: false,
          canModal: true,
        },
        roles: [UserRole.logged],
        pageInfo: {
          show: true,
          i18nTitle: "expense.expenseEdit.title",
        },
      },
    },
    {
      name: "expense-detail",
      path: ":idFrais/detail",
      component: () =>
        import(
          "@/pages/bureau/views/expense-report/subviews/frais-detail/FraisDetail.vue"
        ),
      meta: {
        layout: {
          type: LayoutEnum.default,
          strict: true,
          showNavbarOnMobile: false,
          canModal: true,
        },
        roles: [UserRole.logged],
        pageInfo: {
          show: true,
          i18nTitle: "expense.expenseDetail.title",
        },
      },
    },
    {
      name: "reports",
      path: "reports",
      component: () =>
        import(
          "@/pages/bureau/views/expense-report/subviews/note-list/NotesList.vue"
        ),
      meta: {
        layout: {
          type: LayoutEnum.default,
          strict: true,
          showNavbarOnMobile: false,
          canModal: false,
        },
        roles: [UserRole.logged],
        pageInfo: {
          show: true,
          i18nTitle: "expense.expenseReportList.title",
        },
      },
      children: [
        {
          name: "report-detail",
          path: ":idNote/detail",
          component: () =>
            import(
              "@/pages/bureau/views/expense-report/subviews/note-detail/NoteDetail.vue"
            ),
          meta: {
            layout: {
              type: LayoutEnum.default,
              strict: true,
              showNavbarOnMobile: false,
              canModal: false,
            },
            roles: [UserRole.logged],
            pageInfo: {
              show: true,
              i18nTitle: "expense.expenseReportDetail.title",
            },
          },
        },
      ],
    },

    {
      name: "report-edit",
      path: "reports/:idNote/edit",
      component: () =>
        import(
          "@/pages/bureau/views/expense-report/subviews/note-edit/NoteEdit.vue"
        ),
      meta: {
        layout: {
          type: LayoutEnum.default,
          strict: true,
          showNavbarOnMobile: false,
          canModal: false,
        },
        roles: [UserRole.logged],
        pageInfo: {
          show: true,
          i18nTitle: "expense.expenseReportEdit.title",
        },
      },
    },
    {
      name: "statistiques",
      path: "statistiques",
      component: () =>
        import(
          "@/pages/bureau/views/expense-report/subviews/statistique/ExpenseStatistique.vue"
        ),
      meta: {
        layout: {
          type: LayoutEnum.default,
          strict: true,
          showNavbarOnMobile: false,
          canModal: false,
        },
        roles: [UserRole.logged],
        pageInfo: {
          show: true,
          i18nTitle: "expense.statistique.title",
        },
      },
    },
  ],
};

export const pointagesRoute: Route = {
  name: "pointages",
  path: "/pointages",
  component: () =>
    import("@/pages/bureau/views/pointages/pointages-list/PointagesList.vue"),
  meta: {
    layout: {
      type: LayoutEnum.default,
      strict: true,
      showNavbarOnMobile: false,
    },
    roles: [UserRole.logged],
    navInfo: {
      order: 2,
      navGroup: NavGroup.perso,
      i18nLabel: "navigation.pointages",
      icon: "offer",
    },
    pageInfo: {
      show: true,
      i18nTitle: "pointages.list.title",
    },
  },
  children: [
    {
      name: "pointage-detail",
      path: ":idPointage/detail",
      component: () =>
        import(
          "@/pages/bureau/views/pointages/pointage-detail/PointageDetail.vue"
        ),
      meta: {
        pageInfo: {
          show: true,
          i18nTitle: "pointages.detail.title",
        },
      },
    },
    {
      name: "pointage-form-new",
      path: "new",
      component: () =>
        import("@/pages/bureau/views/pointages/pointage-form/PointageForm.vue"),
      meta: {
        pageInfo: {
          show: true,
          i18nTitle: "pointages.form.new",
        },
      },
    },
    {
      name: "pointage-form-edit",
      path: ":idPointage/form",
      component: () =>
        import("@/pages/bureau/views/pointages/pointage-form/PointageForm.vue"),
      meta: {
        pageInfo: {
          show: true,
          i18nTitle: "pointages.form.edit",
        },
      },
    },
    {
      name: "pointage-stats",
      path: "stats",
      component: () =>
        import(
          "@/pages/bureau/views/pointages/pointages-stats/PointagesStats.vue"
        ),
      meta: {
        pageInfo: {
          show: true,
          i18nTitle: "pointages.stats.title",
        },
      },
    },
  ],
};

export const filRoute: Route = {
  name: "fdc",
  path: "/fdc",
  component: () => import("@/pages/bureau/views/fil/FilBase.vue"),
  meta: {
    layout: {
      type: LayoutEnum.default,
      strict: true,
      showNavbarOnMobile: false,
    },
    roles: [UserRole.logged],
    navInfo: {
      order: 2,
      navGroup: NavGroup.perso,
      i18nLabel: "navigation.fil",
      icon: "offer",
    },
    pageInfo: {
      show: true,
      i18nTitle: "fil.categories.title",
    },
  },
  children: [
    {
      name: "fdc-conversation",
      path: ":uidGroup",
      component: () => import("@/pages/bureau/views/fil/FilConversation.vue"),
      meta: {
        layout: {
          type: LayoutEnum.default,
          strict: true,
          showNavbarOnMobile: false,
          canModal: false,
        },
        roles: [UserRole.logged],
        pageInfo: {
          show: true,
          i18nTitle: "fil.conversation.title",
        },
      },
    },
  ],
};

export const tasksRoute: Route = {
  name: "tasks",
  path: "/tasks",
  component: () => import("@/pages/bureau/views/tasks/TasksBase.vue"),
  meta: {
    layout: {
      type: LayoutEnum.default,
      strict: true,
      showNavbarOnMobile: false,
    },
    roles: [UserRole.logged],
    navInfo: {
      order: 2,
      navGroup: NavGroup.perso,
      i18nLabel: "navigation.tasks",
      icon: "offer",
    },
    pageInfo: {
      show: true,
      i18nTitle: "taches.form.title",
    },
  },
  children: [
    {
      name: "tasks-details",
      path: ":indexTache/details",
      component: () =>
        import("@/pages/bureau/views/tasks/tasks-details/TasksDetails.vue"),
      meta: {
        layout: {
          type: LayoutEnum.default,
          strict: true,
          showNavbarOnMobile: false,
          canModal: false,
        },
        roles: [UserRole.logged],
        pageInfo: {
          show: true,
          i18nTitle: "taches.details.title",
        },
      },
      children: [
        {
          name: "tasks-documents",
          path: "documents",
          component: () =>
            import(
              "@/pages/bureau/views/tasks/tasks-documents/TasksDocuments.vue"
            ),
          meta: {
            layout: {
              type: LayoutEnum.default,
              strict: true,
              showNavbarOnMobile: false,
              canModal: false,
            },
            roles: [UserRole.logged],
            pageInfo: {
              show: true,
              i18nTitle: "taches.documents.title",
            },
          },
        },
        {
          name: "tasks-fdc",
          path: "fdc/:uidGroup",
          component: () =>
            import("@/pages/bureau/views/fil/FilConversationParent.vue"),
          meta: {
            layout: {
              type: LayoutEnum.default,
              strict: true,
              showNavbarOnMobile: false,
              canModal: false,
            },
            roles: [UserRole.logged],
            pageInfo: {
              show: true,
              i18nTitle: "navigation.fil",
            },
          },
        },
        {
          name: "tasks-fdc-join",
          path: "fdc/join",
          component: () =>
            import("@/pages/bureau/views/fil/FilConversationParent.vue"),
          meta: {
            layout: {
              type: LayoutEnum.default,
              strict: true,
              showNavbarOnMobile: false,
              canModal: false,
            },
            roles: [UserRole.logged],
            pageInfo: {
              show: true,
              i18nTitle: "navigation.fil",
            },
          },
        },
      ],
    },

    {
      name: "tasks-edit",
      path: ":indexTache/edit",
      component: () =>
        import("@/pages/bureau/views/tasks/tasks-form/TasksForm.vue"),
      meta: {
        layout: {
          type: LayoutEnum.default,
          strict: true,
          showNavbarOnMobile: false,
          canModal: false,
        },
        roles: [UserRole.logged],
        pageInfo: {
          show: true,
          i18nTitle: "taches.edit.title",
        },
      },
    },
    {
      name: "tasks-new-perso",
      path: "new-p",
      component: () =>
        import("@/pages/bureau/views/tasks/tasks-form/TasksForm.vue"),
      meta: {
        layout: {
          type: LayoutEnum.default,
          strict: true,
          showNavbarOnMobile: false,
          canModal: false,
        },
        roles: [UserRole.logged],
        pageInfo: {
          show: true,
          i18nTitle: "taches.edit.personal",
        },
      },
    },
    {
      name: "tasks-new-collabo",
      path: "new-c",
      component: () =>
        import("@/pages/bureau/views/tasks/tasks-form/TasksForm.vue"),
      meta: {
        layout: {
          type: LayoutEnum.default,
          strict: true,
          showNavbarOnMobile: false,
          canModal: false,
        },
        roles: [UserRole.logged],
        pageInfo: {
          show: true,
          i18nTitle: "taches.edit.collabo",
        },
      },
    },
  ],
};

export const intervenantsRoute: Route = {
  name: "intervenants",
  path: "/intervenants",
  component: () =>
    import("@/pages/bureau/views/intervenants/IntervenantsBase.vue"),
  meta: {
    navInfo: {
      order: 2,
      navGroup: NavGroup.data,
    },
    layout: {
      type: LayoutEnum.default,
      strict: true,
      showNavbarOnMobile: false,
      canModal: false,
    },
    pageInfo: {
      show: true,
      i18nTitle: "intervenants.list.title",
    },
    roles: [UserRole.logged],
  },
  children: [
    {
      name: "intervenants-detail",
      path: ":idIntervenant/detail",
      component: () =>
        import(
          "@/pages/bureau/views/intervenants/intervenants-detail/IntervenantsDetail.vue"
        ),
      meta: {
        layout: {
          type: LayoutEnum.default,
          strict: true,
          showNavbarOnMobile: false,
          canModal: false,
        },
        roles: [UserRole.logged],
        pageInfo: {
          show: true,
          i18nTitle: "intervenants.detail.title",
        },
      },
      children: [
        {
          name: "intervenants-fdc",
          path: "fdc/:uidGroup",
          component: () =>
            import("@/pages/bureau/views/fil/FilConversationParent.vue"),
          meta: {
            layout: {
              type: LayoutEnum.default,
              strict: true,
              showNavbarOnMobile: false,
              canModal: false,
            },
            roles: [UserRole.logged],
            pageInfo: {
              show: true,
              i18nTitle: "navigation.fil",
            },
          },
        },
        {
          name: "intervenants-fdc-join",
          path: "fdc/join",
          component: () =>
            import("@/pages/bureau/views/fil/FilConversationParent.vue"),
          meta: {
            layout: {
              type: LayoutEnum.default,
              strict: true,
              showNavbarOnMobile: false,
              canModal: false,
            },
            roles: [UserRole.logged],
            pageInfo: {
              show: true,
              i18nTitle: "navigation.fil",
            },
          },
        },
      ],
    },
    {
      name: "intervenants-contacts",
      path: ":idIntervenant/contacts",
      component: () =>
        import(
          "@/pages/bureau/views/intervenants/intervenants-contacts/IntervenantsContacts.vue"
        ),
      meta: {
        layout: {
          type: LayoutEnum.default,
          strict: true,
          showNavbarOnMobile: false,
          canModal: false,
        },
        roles: [UserRole.logged],
        pageInfo: {
          show: true,
          i18nTitle: "intervenants.contacts.title",
        },
      },
      children: [
        {
          name: "intervenant-contact-form",
          path: ":idContact/form",
          component: () =>
            import(
              "@/pages/bureau/views/intervenants/intervenants-contacts/IntervenantsContactForm.vue"
            ),
          meta: {
            layout: {
              type: LayoutEnum.default,
              strict: true,
              showNavbarOnMobile: false,
              canModal: false,
            },
            roles: [UserRole.logged],
            pageInfo: {
              show: true,
              i18nTitle: "intervenants.contacts.form",
            },
          },
        },
      ],
    },
    {
      name: "intervenants-docs",
      path: ":idIntervenant/docs",
      component: () =>
        import(
          "@/pages/bureau/views/intervenants/intervenants-docs/IntervenantsDocs.vue"
        ),
      meta: {
        layout: {
          type: LayoutEnum.default,
          strict: true,
          showNavbarOnMobile: false,
          canModal: false,
        },
        roles: [UserRole.logged],
        pageInfo: {
          show: true,
          i18nTitle: "intervenants.docs.title",
        },
      },
      children: [
        {
          name: "intervenants-docs-new",
          path: "new",
          component: () =>
            import(
              "@/pages/bureau/views/intervenants/intervenants-docs/IntervenantsDocsNew.vue"
            ),
          meta: {
            layout: {
              type: LayoutEnum.default,
              strict: true,
              showNavbarOnMobile: false,
              canModal: false,
            },
            roles: [UserRole.logged],
            pageInfo: {
              show: true,
              i18nTitle: "intervenants.docs.new",
            },
          },
        },
        {
          name: "intervenants-docs-details",
          path: ":uidDoc/details",
          component: () =>
            import(
              "@/pages/bureau/views/intervenants/intervenants-docs/IntervenantsDocsDetails.vue"
            ),
          meta: {
            layout: {
              type: LayoutEnum.default,
              strict: true,
              showNavbarOnMobile: false,
              canModal: false,
            },
            roles: [UserRole.logged],
            pageInfo: {
              show: true,
              i18nTitle: "intervenants.docs.details",
            },
          },
        },
      ],
    },
    {
      name: "intervenants-edit",
      path: ":idIntervenant/edit",
      component: () =>
        import(
          "@/pages/bureau/views/intervenants/intervenants-edit/IntervenantsEdit.vue"
        ),
      meta: {
        layout: {
          type: LayoutEnum.default,
          strict: true,
          showNavbarOnMobile: false,
          canModal: false,
        },
        roles: [UserRole.logged],
        pageInfo: {
          show: true,
          i18nTitle: "intervenants.edit.title",
        },
      },
    },
    {
      name: "intervenants-new",
      path: "new",
      component: () =>
        import(
          "@/pages/bureau/views/intervenants/intervenants-new/IntervenantsNew.vue"
        ),
      meta: {
        layout: {
          type: LayoutEnum.default,
          strict: true,
          showNavbarOnMobile: false,
          canModal: false,
        },
        roles: [UserRole.logged],
        pageInfo: {
          show: true,
          i18nTitle: "intervenants.new.title",
        },
      },
    },
  ],
};

export const projectsRoute: Route = {
  name: "projects",
  path: "/projects",
  component: () => import("@/pages/bureau/views/projects/ProjectBase.vue"),
  meta: {
    layout: {
      type: LayoutEnum.default,
      strict: true,
      showNavbarOnMobile: false,
      canModal: false,
    },
    roles: [UserRole.logged],
    navInfo: {
      order: 2,
      navGroup: NavGroup.data,
      i18nLabel: "navigation.projects",
      icon: "preview",
    },
    pageInfo: {
      show: true,
      i18nTitle: "projects.list.title",
    },
  },
  children: [
    {
      name: "project-detail",
      path: ":idProjet/detail",
      component: () =>
        import(
          "@/pages/bureau/views/projects/project-detail/ProjectDetail.vue"
        ),
      meta: {
        layout: {
          type: LayoutEnum.default,
          strict: true,
          showNavbarOnMobile: false,
          canModal: false,
        },
        roles: [UserRole.logged],
        pageInfo: {
          show: true,
          i18nTitle: "projects.detail.title",
        },
      },
      children: [
        {
          name: "project-fdc",
          path: "fdc/:uidGroup",
          component: () =>
            import("@/pages/bureau/views/fil/FilConversationParent.vue"),
          meta: {
            layout: {
              type: LayoutEnum.default,
              strict: true,
              showNavbarOnMobile: false,
              canModal: false,
            },
            roles: [UserRole.logged],
            pageInfo: {
              show: true,
              i18nTitle: "navigation.fil",
            },
          },
        },
        {
          name: "project-fdc-join",
          path: "fdc/join",
          component: () =>
            import("@/pages/bureau/views/fil/FilConversationParent.vue"),
          meta: {
            layout: {
              type: LayoutEnum.default,
              strict: true,
              showNavbarOnMobile: false,
              canModal: false,
            },
            roles: [UserRole.logged],
            pageInfo: {
              show: true,
              i18nTitle: "navigation.fil",
            },
          },
        },
      ],
    },
    {
      name: "project-edit",
      path: ":idProjet/edit",
      component: () =>
        import("@/pages/bureau/views/projects/project-edit/ProjectEdit.vue"),
      meta: {
        layout: {
          type: LayoutEnum.default,
          strict: true,
          showNavbarOnMobile: false,
          canModal: false,
        },
        roles: [UserRole.logged],
        pageInfo: {
          show: true,
          i18nTitle: "projects.edit.title",
        },
      },
    },
    {
      name: "project-new",
      path: "new",
      component: () =>
        import("@/pages/bureau/views/projects/project-new/ProjectNew.vue"),
      meta: {
        layout: {
          type: LayoutEnum.default,
          strict: true,
          showNavbarOnMobile: false,
          canModal: false,
        },
        roles: [UserRole.logged],
        pageInfo: {
          show: true,
          i18nTitle: "projects.new.title",
        },
      },
    },
    {
      name: "project-annuaire",
      path: ":idProjet/annuaire",
      component: () =>
        import(
          "@/pages/bureau/views/projects/project-annuaire/ProjectAnnuaire.vue"
        ),
      meta: {
        layout: {
          type: LayoutEnum.default,
          strict: true,
          showNavbarOnMobile: false,
          canModal: false,
        },
        roles: [UserRole.logged],
        pageInfo: {
          show: true,
          i18nTitle: "projects.annuaire.title",
        },
      },
      children: [
        {
          name: "project-intervenant-list",
          path: "intervenants",
          component: () =>
            import(
              "@/pages/bureau/views/projects/project-annuaire/project-intervenant-list/ProjectIntervenantList.vue"
            ),
          meta: {
            layout: {
              type: LayoutEnum.default,
              strict: true,
              showNavbarOnMobile: false,
              canModal: false,
            },
            roles: [UserRole.logged],
            pageInfo: {
              show: true,
              i18nTitle: "projects.annuaire.intervenants",
            },
          },
          children: [
            {
              name: "project-intervenant-new",
              path: ":indexInter/new",
              component: () =>
                import(
                  "@/pages/bureau/views/projects/project-annuaire/project-intervenant-form/ProjectIntervenantForm.vue"
                ),
              meta: {
                layout: {
                  type: LayoutEnum.default,
                  strict: true,
                  showNavbarOnMobile: false,
                  canModal: false,
                },
                roles: [UserRole.logged],
                pageInfo: {
                  show: true,
                  i18nTitle: "intervenants.contacts.form",
                },
              },
            },
            {
              name: "project-intervenant-edit",
              path: ":indexInterProj/edit",
              component: () =>
                import(
                  "@/pages/bureau/views/projects/project-annuaire/project-intervenant-form/ProjectIntervenantForm.vue"
                ),
              meta: {
                layout: {
                  type: LayoutEnum.default,
                  strict: true,
                  showNavbarOnMobile: false,
                  canModal: false,
                },
                roles: [UserRole.logged],
                pageInfo: {
                  show: true,
                  i18nTitle: "intervenants.contacts.form",
                },
              },
            },
            {
              name: "project-intervenant-detail",
              path: ":indexInterProjet/detail",
              component: () =>
                import(
                  "@/pages/bureau/views/projects/project-annuaire/project-intervenant-detail/ProjectIntervenantDetail.vue"
                ),
              meta: {
                layout: {
                  type: LayoutEnum.default,
                  strict: true,
                  showNavbarOnMobile: false,
                  canModal: false,
                },
                roles: [UserRole.logged],
                pageInfo: {
                  show: true,
                  i18nTitle: "projects.annuaire.detail",
                },
              },
              children: [
                {
                  name: "project-intervenant-fdc",
                  path: "fdc/:uidGroup",
                  component: () =>
                    import(
                      "@/pages/bureau/views/fil/FilConversationParent.vue"
                    ),
                  meta: {
                    layout: {
                      type: LayoutEnum.default,
                      strict: true,
                      showNavbarOnMobile: false,
                      canModal: false,
                    },
                    roles: [UserRole.logged],
                    pageInfo: {
                      show: true,
                      i18nTitle: "navigation.fil",
                    },
                  },
                },
                {
                  name: "project-intervenant-fdc-join",
                  path: "fdc/join",
                  component: () =>
                    import(
                      "@/pages/bureau/views/fil/FilConversationParent.vue"
                    ),
                  meta: {
                    layout: {
                      type: LayoutEnum.default,
                      strict: true,
                      showNavbarOnMobile: false,
                      canModal: false,
                    },
                    roles: [UserRole.logged],
                    pageInfo: {
                      show: true,
                      i18nTitle: "navigation.fil",
                    },
                  },
                },
              ],
            },
            {
              name: "project-intervenant-contacts",
              path: ":indexInterProjet/contacts",
              component: () =>
                import(
                  "@/pages/bureau/views/projects/project-annuaire/project-intervenant-contacts/ProjectIntervenantContacts.vue"
                ),
              meta: {
                layout: {
                  type: LayoutEnum.default,
                  strict: true,
                  showNavbarOnMobile: false,
                  canModal: false,
                },
                roles: [UserRole.logged],
                pageInfo: {
                  show: true,
                  i18nTitle: "projects.annuaire.contacts",
                },
              },
              children: [
                {
                  name: "project-intervenant-contacts-form",
                  path: ":idContact/form",
                  component: () =>
                    import(
                      "@/pages/bureau/views/projects/project-annuaire/project-intervenant-contacts/ProjectIntervenantContactForm.vue"
                    ),
                  meta: {
                    layout: {
                      type: LayoutEnum.default,
                      strict: true,
                      showNavbarOnMobile: false,
                      canModal: false,
                    },
                    roles: [UserRole.logged],
                    pageInfo: {
                      show: true,
                      i18nTitle: "intervenants.contacts.form",
                    },
                  },
                },
              ],
            },
          ],
        },
        {
          name: "project-equipeinterne",
          path: "collaborateurs",
          component: () =>
            import(
              "@/pages/bureau/views/projects/project-annuaire/project-equipeinterne/project-equipeinterne-list/ProjectEquipeInterneList.vue"
            ),
          meta: {
            layout: {
              type: LayoutEnum.default,
              strict: true,
              showNavbarOnMobile: false,
              canModal: false,
            },
            roles: [UserRole.logged],
            pageInfo: {
              show: true,
              i18nTitle: "projects.annuaire.equipeInterne",
            },
          },
          children: [
            {
              name: "project-equipeinterne-detail",
              path: ":indexParticipant/detail",
              component: () =>
                import(
                  "@/pages/bureau/views/projects/project-annuaire/project-equipeinterne/project-equipeinterne-detail/ProjectEquipeInterneDetail.vue"
                ),
              meta: {
                layout: {
                  type: LayoutEnum.default,
                  strict: true,
                  showNavbarOnMobile: false,
                  canModal: false,
                },
                roles: [UserRole.logged],
                pageInfo: {
                  show: true,
                  i18nTitle: "projects.annuaire.detail",
                },
              },
            },
            {
              name: "project-equipeinterne-tasks",
              path: ":indexParticipant/tasks",
              component: () =>
                import(
                  "@/pages/bureau/views/projects/project-annuaire/project-equipeinterne/project-equipeinterne-tasks/ProjectEquipeInterneTasks.vue"
                ),
              meta: {
                layout: {
                  type: LayoutEnum.default,
                  strict: true,
                  showNavbarOnMobile: false,
                  canModal: false,
                },
                roles: [UserRole.logged],
                pageInfo: {
                  show: true,
                  i18nTitle: "taches.form.title",
                },
              },
              children: [
                {
                  name: "project-equipeinterne-tasks-details",
                  path: ":indexTache/details",
                  component: () =>
                    import(
                      "@/pages/bureau/views/projects/project-tasks/project-task-details/ProjectTaskDetails.vue"
                    ),
                  meta: {
                    layout: {
                      type: LayoutEnum.default,
                      strict: true,
                      showNavbarOnMobile: false,
                      canModal: false,
                    },
                    roles: [UserRole.logged],
                    pageInfo: {
                      show: true,
                      i18nTitle: "taches.details.title",
                    },
                  },
                  children: [
                    {
                      name: "project-equipeinterne-tasks-fdc",
                      path: "fdc/:uidGroup",
                      component: () =>
                        import(
                          "@/pages/bureau/views/fil/FilConversationParent.vue"
                        ),
                      meta: {
                        layout: {
                          type: LayoutEnum.default,
                          strict: true,
                          showNavbarOnMobile: false,
                          canModal: false,
                        },
                        roles: [UserRole.logged],
                        pageInfo: {
                          show: true,
                          i18nTitle: "navigation.fil",
                        },
                      },
                    },
                    {
                      name: "project-equipeinterne-tasks-fdc-join",
                      path: "fdc/join",
                      component: () =>
                        import(
                          "@/pages/bureau/views/fil/FilConversationParent.vue"
                        ),
                      meta: {
                        layout: {
                          type: LayoutEnum.default,
                          strict: true,
                          showNavbarOnMobile: false,
                          canModal: false,
                        },
                        roles: [UserRole.logged],
                        pageInfo: {
                          show: true,
                          i18nTitle: "navigation.fil",
                        },
                      },
                    },
                    {
                      name: "project-equipeinterne-tasks-details-edit",
                      path: "edit",
                      component: () =>
                        import(
                          "@/pages/bureau/views/tasks/tasks-form/TasksForm.vue"
                        ),
                      meta: {
                        layout: {
                          type: LayoutEnum.default,
                          strict: true,
                          showNavbarOnMobile: false,
                          canModal: false,
                        },
                        roles: [UserRole.logged],
                        pageInfo: {
                          show: true,
                          i18nTitle: "taches.edit.collabo",
                        },
                      },
                    },
                    {
                      name: "project-equipeinterne-tasks-details-documents",
                      path: "documents",
                      component: () =>
                        import(
                          "@/pages/bureau/views/tasks/tasks-documents/TasksDocuments.vue"
                        ),
                      meta: {
                        layout: {
                          type: LayoutEnum.default,
                          strict: true,
                          showNavbarOnMobile: false,
                          canModal: false,
                        },
                        roles: [UserRole.logged],
                        pageInfo: {
                          show: true,
                          i18nTitle: "taches.documents.title",
                        },
                      },
                    },
                  ],
                },
                {
                  name: "project-equipeinterne-tasks-new",
                  path: "new",
                  component: () =>
                    import(
                      "@/pages/bureau/views/tasks/tasks-form/TasksForm.vue"
                    ),
                  meta: {
                    layout: {
                      type: LayoutEnum.default,
                      strict: true,
                      showNavbarOnMobile: false,
                      canModal: false,
                    },
                    roles: [UserRole.logged],
                    pageInfo: {
                      show: true,
                      i18nTitle: "taches.edit.collabo",
                    },
                  },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: "project-docs",
      path: ":idProjet/docs",
      component: () =>
        import("@/pages/bureau/views/projects/project-docs/ProjectDocs.vue"),
      meta: {
        layout: {
          type: LayoutEnum.default,
          strict: true,
          showNavbarOnMobile: false,
          canModal: false,
        },
        roles: [UserRole.logged],
        pageInfo: {
          show: true,
          i18nTitle: "projects.docs.title",
        },
      },
    },
    {
      name: "project-notes",
      path: ":idProjet/notes",
      component: () =>
        import("@/pages/bureau/views/projects/project-notes/ProjectNotes.vue"),
      meta: {
        layout: {
          type: LayoutEnum.default,
          strict: true,
          showNavbarOnMobile: false,
          canModal: false,
        },
        roles: [UserRole.logged],
        pageInfo: {
          show: true,
          i18nTitle: "projects.notes.title",
        },
      },
    },
    {
      name: "project-tasks",
      path: ":idProjet/tasks",
      component: () =>
        import(
          "@/pages/bureau/views/projects/project-tasks/project-tasks-list/ProjectTasksList.vue"
        ),
      meta: {
        layout: {
          type: LayoutEnum.default,
          strict: true,
          showNavbarOnMobile: false,
          canModal: false,
        },
        roles: [UserRole.logged],
        pageInfo: {
          show: true,
          i18nTitle: "taches.form.title",
        },
      },
      children: [
        {
          name: "project-tasks-details",
          path: ":indexTache/details",
          component: () =>
            import(
              "@/pages/bureau/views/projects/project-tasks/project-task-details/ProjectTaskDetails.vue"
            ),
          meta: {
            layout: {
              type: LayoutEnum.default,
              strict: true,
              showNavbarOnMobile: false,
              canModal: false,
            },
            roles: [UserRole.logged],
            pageInfo: {
              show: true,
              i18nTitle: "taches.details.title",
            },
          },
          children: [
            {
              name: "project-tasks-fdc",
              path: "fdc/:uidGroup",
              component: () =>
                import("@/pages/bureau/views/fil/FilConversationParent.vue"),
              meta: {
                layout: {
                  type: LayoutEnum.default,
                  strict: true,
                  showNavbarOnMobile: false,
                  canModal: false,
                },
                roles: [UserRole.logged],
                pageInfo: {
                  show: true,
                  i18nTitle: "navigation.fil",
                },
              },
            },
            {
              name: "project-tasks-fdc-join",
              path: "fdc/join",
              component: () =>
                import("@/pages/bureau/views/fil/FilConversationParent.vue"),
              meta: {
                layout: {
                  type: LayoutEnum.default,
                  strict: true,
                  showNavbarOnMobile: false,
                  canModal: false,
                },
                roles: [UserRole.logged],
                pageInfo: {
                  show: true,
                  i18nTitle: "navigation.fil",
                },
              },
            },
            {
              name: "project-tasks-details-edit",
              path: "edit",
              component: () =>
                import("@/pages/bureau/views/tasks/tasks-form/TasksForm.vue"),
              meta: {
                layout: {
                  type: LayoutEnum.default,
                  strict: true,
                  showNavbarOnMobile: false,
                  canModal: false,
                },
                roles: [UserRole.logged],
                pageInfo: {
                  show: true,
                  i18nTitle: "taches.edit.collabo",
                },
              },
            },

            {
              name: "project-tasks-details-documents",
              path: "documents",
              component: () =>
                import(
                  "@/pages/bureau/views/tasks/tasks-documents/TasksDocuments.vue"
                ),
              meta: {
                layout: {
                  type: LayoutEnum.default,
                  strict: true,
                  showNavbarOnMobile: false,
                  canModal: false,
                },
                roles: [UserRole.logged],
                pageInfo: {
                  show: true,
                  i18nTitle: "taches.documents.title",
                },
              },
            },
          ],
        },
        {
          name: "project-tasks-new",
          path: "new",
          component: () =>
            import("@/pages/bureau/views/tasks/tasks-form/TasksForm.vue"),
          meta: {
            layout: {
              type: LayoutEnum.default,
              strict: true,
              showNavbarOnMobile: false,
              canModal: false,
            },
            roles: [UserRole.logged],
            pageInfo: {
              show: true,
              i18nTitle: "taches.edit.collabo",
            },
          },
        },
      ],
    },
  ],
};

export const rdvRoute: Route = {
  name: "rdv",
  path: "/rdv",
  component: () => import("@/pages/bureau/views/rdv/RDVBase.vue"),
  meta: {
    layout: {
      type: LayoutEnum.default,
      strict: true,
    },
    roles: [UserRole.logged],
    navInfo: {
      order: 2,
      navGroup: NavGroup.perso,
      i18nLabel: "navigation.rdv",
      icon: "offer",
    },
    pageInfo: {
      show: true,
      i18nTitle: "expenseReport.title",
    },
  },
};

export const dataBaseRoute: Route = {
  name: "base-connaissance",
  path: "/base-connaissance",
  component: () => import("@/pages/bureau/views/data-base/DataBase.vue"),
  meta: {
    layout: {
      type: LayoutEnum.default,
      strict: true,
      showNavbarOnMobile: false,
      canModal: false,
    },
    roles: [UserRole.logged],
    navInfo: {
      order: 2,
      navGroup: NavGroup.data,
      i18nLabel: "navigation.dataBase",
      icon: "offer",
    },
    pageInfo: {
      show: true,
      i18nTitle: "navigation.dataBase",
    },
  },
};

/**
 * Contient toutes les routes toujours présentes
 *
 **/
export const baseRoutes: Route[] = [
  {
    name: "login",
    path: "/login",
    component: () => import("@/pages/bureau/views/login/Login.vue"),
    meta: {
      layout: {
        type: LayoutEnum.empty,
        strict: true,
      },
    },
  },

  {
    name: "preview_login",
    path: "/preview_login",
    component: () => import("@/pages/bureau/views/PreviewLogin.vue"),
    meta: {
      layout: {
        type: LayoutEnum.empty,
        strict: true,
      },
    },
  },
  {
    path: "/",
    component: () => import("@/pages/bureau/views/ViewBase.vue"),
    redirect: { name: "home" },
    children: [
      previewRoute,
      homeRoute,
      infosRoute,
      expensesRoute,
      pointagesRoute,
      tasksRoute,
      filRoute,
      projectsRoute,
      intervenantsRoute,
      rdvRoute,
      dataBaseRoute,
      {
        name: "settings",
        path: "/settings",
        component: () =>
          import("@/pages/bureau/views/settings/SettingsBase.vue"),
        meta: {
          layout: {
            type: LayoutEnum.default,
            strict: true,
          },
          roles: [UserRole.logged],
          navInfo: {
            order: 1,
            navGroup: NavGroup.params,
            i18nLabel: "navigation.settings",
            icon: "offer",
          },
          pageInfo: {
            show: true,
            i18nTitle: "global.settings.title",
          },
        },
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: () => import("@/pages/bureau/views/not-found/NotFound.vue"),
    meta: {
      layout: {
        type: LayoutEnum.default,
        strict: true,
      },
      roles: [UserRole.logged],
      pageInfo: {
        show: true,
        i18nTitle: "global.notFound.title",
      },
    },
  },
  // if you omit the last `*`, the `/` character in params will be encoded when resolving or pushing
  {
    path: "/:pathMatch(.*)",
    name: "not-found",
    component: () => import("@/pages/bureau/views/not-found/NotFound.vue"),
    meta: {
      layout: {
        type: LayoutEnum.default,
        strict: true,
      },
      roles: [UserRole.logged],
      pageInfo: {
        show: true,
        i18nTitle: "global.notFound.title",
      },
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes: baseRoutes,
  scrollBehavior: (to, from, savedPosition): any => {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
      };
    } else {
      return { x: 0, y: 0, behavior: "smooth" };
    }
  },
});
async function fetchAllAccess() {
  if (!accessTemp.isLoaded()) {
    await awaitData(() => UserModule.token);
    await AccessModule.checkAllAccess();
  }
}
router.beforeEach(async (to, from, next) => {
  if (isPreviewInUrl()) {
    if (!accessTemp.isLoaded()) {
      await AccessModule.checkAllAccess();
    }
    next();
    return;
  }

  fetchAllAccess();

  if (
    to.path.includes("projects") &&
    (await AccessModule.waitIsLoaded()) &&
    !accessTemp.get("PROJETS-READ")
  ) {
    next("/");
  } else if (
    to.path.includes("intervenants") &&
    (await AccessModule.waitIsLoaded()) &&
    !accessTemp.get("INTERVENANTS-READ")
  ) {
    next("/");
  } else if (
    //pointages
    to.path.includes("pointages") &&
    (await AccessModule.waitIsLoaded()) &&
    !accessTemp.get("POINTAGES-READ")
  ) {
    next("/");
  } else if (
    //pointages
    to.path.includes("base-connaissance") &&
    (await AccessModule.waitIsLoaded()) &&
    !accessTemp.get("BASE-READ")
  ) {
    next("/");
  } else if (
    //pointages
    to.path.includes("fdc") &&
    (await AccessModule.waitIsLoaded()) &&
    !accessTemp.get("FIL-READ")
  ) {
    next("/");
  } else if (
    //pointages
    to.path.includes("tasks") &&
    (await AccessModule.waitIsLoaded()) &&
    !accessTemp.get("TACHES-READ")
  ) {
    next("/");
  } else if (
    //pointages
    to.path.includes("expenses") &&
    (await AccessModule.waitIsLoaded()) &&
    !accessTemp.get("FRAIS-READ")
  ) {
    next("/");
  } else {
    const { hasAccess } = await hasPathAccess(to);
    if (!hasAccess) {
      //use from to redirect to the previous page
      next(from.path);
      return;
    }

    next();
  }
});
export default router;
