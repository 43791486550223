import moment from 'moment'

export enum DateType {
    timestamp = "YYYYMMDDHHmmss",
    date = "YYYY-MM-DD"
}

/**
 * Recupere la date depuis une chaine timestamp de l'api
 * @param str 
 * @param format par default, le format Timestamp
 * @returns 
 */
export const getDateFromString = (str: string, format?: DateType | string): moment.Moment | null => {
    if (str && str !== "")
        return moment(str, format ?? DateType.timestamp);
    return null;
}

/**
 * Trie une collection par date
 * @param arr collection à trier
 * @param order defaut 'asc'
 * @param dateKey vide si array de date
 */
export const sortArrayByDate = (arr: any[], order?: 'asc' | 'dsc', dateKey?: string, customDateFormat?: DateType | string): any[] => {
    if (arr) {
        return arr.sort((x, y) => {
            const xDate = getDateFromString(dateKey ? x[dateKey] : x, customDateFormat);
            const yDate = getDateFromString(dateKey ? y[dateKey] : y, customDateFormat);
            if (xDate && yDate) {
                return order == "asc" ? xDate.diff(yDate) : yDate.diff(xDate)
            } else {
                if (xDate)
                    return 1
                if (yDate)
                    return -1
            }
            return 0
        })
    }
    return arr;
}

