export function format10digitsWithSpaces   (phone: string)  {

    if(!phone) return phone;

    //supprimer tout les espaces actuels
    //verifier si le numero commence par 0
    //verifier si il y a 10 chiffres
    //ajouter un espace tout les 2 chiffres

    const phoneWithoutSpaces = phone.replace(/\s/g, '');
    const phoneStartsWithZero = phoneWithoutSpaces.startsWith('0');
    const phoneLengthIs10 = phoneWithoutSpaces.length === 10;

    if (phoneStartsWithZero && phoneLengthIs10) {
        const phoneWithSpaces = phoneWithoutSpaces.replace(/(\d{2})/g, '$1 ');
        return phoneWithSpaces;
    } else {
        return phone;
    }



}

export function format10NumbersWithPrefix(prefix = "", phone = "") {
  if (phone.length == 9) {
    phone = "0" + phone;
  }
  if (prefix.length > 0 && phone[0] == "0") {
    return prefix + " " + format10digitsWithSpaces(phone).substring(1);
  } else if (prefix.length > 0) {
    return prefix + " " + format10digitsWithSpaces(phone);
  } else {
    return format10digitsWithSpaces(phone);
  }
}