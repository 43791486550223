import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, mergeProps as _mergeProps, Transition as _Transition, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  tabindex: "-1",
  "aria-hidden": "true",
  class: "fixed inset-0 z-[69] flex backdrop-blur-sm"
}
const _hoisted_2 = {
  key: 0,
  class: "sticky flex items-center rounded-t-2xl p-2 md:px-5 md:py-2"
}
const _hoisted_3 = { class: "flex-grow" }
const _hoisted_4 = {
  key: 1,
  class: "text-lg font-semibold sm:text-xl"
}
const _hoisted_5 = ["disabled"]
const _hoisted_6 = {
  key: 1,
  class: "flex rounded-b-2xl"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BIconClose = _resolveComponent("BIconClose")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _renderSlot(_ctx.$slots, "action", {
      open: _ctx.open,
      hide: _ctx.hide
    }),
    _createVNode(_Transition, {
      "enter-active-class": "duration-100 ease-in",
      "enter-from-class": "opacity-0",
      "enter-to-class": "opacity-100",
      "leave-active-class": "duration-200 ease-out",
      "leave-from-class": "opacity-100",
      "leave-to-class": "opacity-0"
    }, {
      default: _withCtx(() => [
        (_ctx.showModal)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("div", {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleBackdropClick($event))),
                tabindex: "-1",
                class: "fixed inset-0 m-0 bg-black p-0 opacity-60 dark:bg-gray-500 dark:opacity-5"
              }),
              _createVNode(_Transition, {
                appear: "",
                "enter-active-class": "duration-200 ease-out",
                "enter-from-class": "scale-95 translate-y-20",
                "enter-to-class": "scale-100 translate-y-0"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _mergeProps({
                    ref: "modalEl",
                    tabindex: "-1"
                  }, _ctx.$attrs, {
                    class: [[
            { ' max-w-2xl rounded-lg md:h-auto': !_ctx.fullscreen },
            { ' h-full w-full rounded-md': _ctx.fullscreen },
          ], "z-70 m-auto box-border flex max-h-full max-w-full flex-col rounded-2xl bg-element text-left text-base opacity-100 shadow-xl transition-all"]
                  }), [
                    (_ctx.hasHeaderSlot || _ctx.title || _ctx.closeButton)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                          _createElementVNode("div", _hoisted_3, [
                            (_ctx.hasHeaderSlot)
                              ? _renderSlot(_ctx.$slots, "header", {
                                  key: 0,
                                  open: _ctx.open,
                                  hide: _ctx.hide
                                })
                              : (_ctx.title)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.title), 1))
                                : _createCommentVNode("", true)
                          ]),
                          (_ctx.closeButton)
                            ? (_openBlock(), _createElementBlock("button", {
                                key: 0,
                                class: "inline-flex items-center rounded-full p-2.5 text-center focus:outline-none focus:ring-4 active:opacity-75",
                                role: "button",
                                disabled: !_ctx.canClose,
                                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.hide()))
                              }, [
                                _createVNode(_component_BIconClose)
                              ], 8, _hoisted_5))
                            : _createCommentVNode("", true)
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", {
                      id: "modalContent",
                      class: _normalizeClass(["flex max-h-full flex-grow overflow-auto", [
              { 'rounded-b-2xl': !_ctx.hasFooterSlot },
              { 'border-t': _ctx.border && (_ctx.hasHeaderSlot || _ctx.title || _ctx.closeButton) },
              { 'border-b': _ctx.border && _ctx.hasFooterSlot },
            ]])
                    }, [
                      _renderSlot(_ctx.$slots, "default", {
                        open: _ctx.open,
                        hide: _ctx.show
                      })
                    ], 2),
                    (_ctx.hasFooterSlot)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                          _renderSlot(_ctx.$slots, "footer", {
                            open: _ctx.open,
                            hide: _ctx.hide
                          })
                        ]))
                      : _createCommentVNode("", true)
                  ], 16)
                ]),
                _: 3
              })
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 3
    })
  ], 64))
}