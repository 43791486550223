
import {
  defineComponent,
  ref,
  PropType,
  watch,
  nextTick,
  computed,
  onUnmounted,
} from "vue";
import BIconClose from "./icons/BIconClose.vue";

export default defineComponent({
  name: "b-modal",
  inheritAttrs: false,
  props: {
    show: {
      type: Boolean,
      required: false,
    },
    backdropClasses: {
      type: String as PropType<string | "default" | "none">,
      required: false,
      default: () => "default",
    },
    fullscreen: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    canClose: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    closeOnBackgroundClick: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    closeButton: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    title: {
      type: String,
      required: false,
    },
    border: Boolean,
  },
  emits: ["hide", "show", "update:show", "backdrop-click"],
  components: {
    BIconClose,
  },
  setup(props, { emit, slots }) {
    const modalEl = ref(null);

    const showModal = ref(props.show == true);

    onUnmounted(() => {
      removeEventListener("keydown", onKeyEscDown);
    });

    watch(
      () => props.show,
      () => (showModal.value = props.show == true)
    );

    watch(
      () => showModal.value,
      async (newValue) => {
        if (newValue) {
          await nextTick();
          if (modalEl.value) {
            modalEl.value.focus();
          }
          emit("show");
          addEventListener("keydown", onKeyEscDown);
        } else {
          emit("hide");
          removeEventListener("keydown", onKeyEscDown);
        }
      }
    );

    const open = () => {
      showModal.value = true;
      emit("update:show", true);
    };

    const hide = () => {
      showModal.value = false;
      emit("update:show", false);
    };

    const handleBackdropClick = (event) => {
      if (props.closeOnBackgroundClick) {
        showModal.value = false;
        emit("update:show", false);
      }

      emit("backdrop-click", event);
    };

    const hasHeaderSlot = computed<boolean>(() => {
      return !!slots.header;
    });

    const hasFooterSlot = computed<boolean>(() => {
      return !!slots.footer;
    });

    const onKeyEscDown = (event: KeyboardEvent) => {
      if (event && event.key == "Escape") {
        hide();
      }
    };

    return {
      showModal,
      modalEl,

      open,
      hide,
      handleBackdropClick,

      hasHeaderSlot,
      hasFooterSlot,
    };
  },
});
