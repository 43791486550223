import { UserAccess } from "@/stores";
import { RouteRecordRaw } from "vue-router";

export const consultationRoutesName = [
  "consultations",
  "consultation",
  "information",
  "dce",
  "questions",
  "lots",
  "offres",
  "bordereaux",
  "candidature",
]

export const consultationRoutes: Array<RouteRecordRaw> = [
  {
    name: "consultations",
    path: "consultations",
    component: () => import("@/views/ao/consultations/ConsultationsList.vue"),
    meta: {
      title: "Consultations",
      access: [UserAccess.consultations],
    },
  },
  {
    name: "consultation",
    path: "consultations/:uid_consultation",
    redirect: {
      name: "information",
    },
    component: () => import("@/views/ao/consultation/ConsultationPage.vue"),
    meta: {
      title: "Consultation",
      access: [UserAccess.consultations],
    },
    children: [
      {
        path: "information",
        name: "information",
        component: () =>
          import("@/views/ao/consultation/subviews/ConsultationDetail.vue"),
        meta: {
          title: "Informations",
          access: [UserAccess.consultations],
        },
      },
      {
        path: "dce",
        name: "dce",
        component: () =>
          import("@/views/ao/consultation/subviews/ConsultationDce.vue"),
        meta: {
          title: "DCE",
          access: [UserAccess.consultations],
        },
      },
      {
        path: "questions",
        name: "questions",
        component: () =>
          import("@/views/ao/consultation/subviews/ConsultationQuestions.vue"),
        meta: {
          title: "Questions/Réponses",
          access: [UserAccess.consultations],
        },
      },
      {
        path: "lots",
        name: "lots",
        component: () =>
          import("@/views/ao/consultation/subviews/ConsultationLots.vue"),
        meta: {
          title: "Sélection lots",
          access: [UserAccess.consultations],
        },
      },
      {
        path: "lots/:uid_lot/offres",
        name: "offres",
        component: () =>
          import("@/views/ao/consultation/subviews/ConsultationOffres.vue"),
        meta: {
          title: "Offres financières",
          access: [UserAccess.consultations],
        },
      },
      {
        path: "lots/:uid_lot/offres/:uid_offre/bordereaux/:uid_bordereau?",
        name: "bordereaux",
        component: () =>
          import("@/views/ao/consultation/subviews/ConsultationBordereaux.vue"),
        meta: {
          title: "Bordereaux",
          access: [UserAccess.consultations],
        },
      },
      {
        path: "candidature",
        name: "candidature",
        component: () =>
          import(
            "@/views/ao/consultation/subviews/ConsultationCandidature.vue"
          ),
        meta: {
          title: "Candidatures",
          access: [UserAccess.consultations],
        },
      },
    ],
  },
];
