import { isMobile as _isMobileDevice } from "@/utils";

export function isMobileWidth(value?: number) {
  /*
      sm: "540px",
      md: "720px",
      lg: "960px",
      xl: "1140px",
      */
  return window.innerWidth < (value != undefined ? value : 540);
}
export function isMobileDevice() {
  return _isMobileDevice();
}
