import { reactive, ref, watch } from "vue";
import { AccessModule } from "../store/modules/access.module";
import { Route } from "../router";
import { RouteLocationNormalized } from "vue-router";
import { h } from "vue";
import { isDev } from "@/utils";

class DefaultClass {
  private access: { [index: string]: boolean } = reactive({});

  public timeoutTimestamp = ref(0);

  constructor() {
    //
  }

  public get(name: IAccessNames) {
    return this.access[name] ?? false;
  }

  public set(name: IAccessNames, value: boolean) {
    this.access[name] = value;
  }

  public async await(name: IAccessNames) {
    if (this.access[name] === undefined) {
      this.access[name] = await AccessModule.checkAccessOf(name);
    }
    return this.get(name);
  }

  public clear() {
    this.access = reactive({});
  }

  public isLoaded() {
    // si projets-read , intervenants-read , taches-read , base-read , pointages-read , frais-read ne sont aps nuls

    return (
      this.access["PROJETS-READ"] !== undefined &&
      this.access["INTERVENANTS-READ"] !== undefined &&
      this.access["TACHES-READ"] !== undefined &&
      this.access["BASE-READ"] !== undefined &&
      this.access["POINTAGES-READ"] !== undefined &&
      this.access["FRAIS-READ"] !== undefined &&
      this.access["FIL-READ"] !== undefined
    );
  }
}
const AccessTemp = new DefaultClass();

export default reactive(AccessTemp);

export const AccessNames = [
  "PROJETS-READ",
  "PROJETS-WRITE",
  "PROJETS-DOCUMENTS-READ",
  "PROJETS-DOCUMENTS-WRITE",

  "INTERVENANTS-READ",
  "INTERVENANTS-ADD",
  "INTERVENANTS-EDIT",

  "TACHES-READ",
  "TACHES-PERSO-READ",
  "TACHES-PERSO-ADD",
  "TACHES-PERSO-EDIT",
  "TACHES-COLLABO-READ",
  "TACHES-COLLABO-ADD",
  "TACHES-COLLABO-EDIT",

  "FIL-READ",
  "FIL-WRITE",

  "BASE-READ",

  "POINTAGES-READ",
  "POINTAGES-ADD",
  "POINTAGES-EDIT",
  "POINTAGES-DELETE",
  "POINTAGES-VALIDER-PERSO",

  "FRAIS-READ",
  "FRAIS-ADD",
  "FRAIS-EDIT",
  "FRAIS-DELETE",
] as const;
export type IAccessNames = (typeof AccessNames)[number];

export async function hasPathAccess(route: RouteLocationNormalized) {
  async function fetchAccess(
    accessNames: IAccessNames[],
    optAccessName_AccessPage: IAccessNames = null
  ) {
    for (const name of accessNames) {
      await AccessTemp.await(name);
    }
    if (optAccessName_AccessPage) {
      return AccessTemp.get(optAccessName_AccessPage);
    }
    return true;
  }

  let hasAccess = true;
  switch (route.name?.toString()) {
    case "expense-detail":
      hasAccess = await fetchAccess(["FRAIS-EDIT", "FRAIS-DELETE"]);
      break;
    case "expense-edit":
      hasAccess = await fetchAccess(["FRAIS-EDIT"], "FRAIS-EDIT");
      break;
    case "expense-new":
      hasAccess = await fetchAccess(["FRAIS-ADD"], "FRAIS-ADD");
      break;
    case "expenses":
      hasAccess = await fetchAccess(["FRAIS-ADD", "FRAIS-DELETE"]);
      break;

    case "intervenants-detail":
      hasAccess = await fetchAccess(["INTERVENANTS-EDIT"]);
      break;
    case "intervenants-fdc":
      hasAccess = await fetchAccess(["FIL-READ", "FIL-WRITE"], "FIL-READ");
      break;
    case "intervenants-fdc-join":
      hasAccess = await fetchAccess(["FIL-READ", "FIL-WRITE"], "FIL-READ");
      break;
    case "intervenants-docs":
      hasAccess = await fetchAccess(["INTERVENANTS-EDIT"]);
      break;
    case "intervenants-docs-new":
      hasAccess = await fetchAccess(["INTERVENANTS-EDIT"], "INTERVENANTS-EDIT");
      break;
    case "intervenants":
      hasAccess = await fetchAccess(["INTERVENANTS-ADD"]);
      break;
    case "intervenants-new":
      hasAccess = await fetchAccess(["INTERVENANTS-ADD"], "INTERVENANTS-ADD");
      break;
    case "intervenants-contacts":
      hasAccess = await fetchAccess(["INTERVENANTS-EDIT"]);
      break;
    case "intervenant-contact-form":
      hasAccess = await fetchAccess(["INTERVENANTS-EDIT"], "INTERVENANTS-EDIT");
      break;

    case "pointages":
      hasAccess = await fetchAccess(["POINTAGES-ADD", "POINTAGES-DELETE"]);
      break;
    case "pointage-form-new":
      hasAccess = await fetchAccess(
        ["POINTAGES-ADD", "POINTAGES-VALIDER-PERSO"],
        "POINTAGES-ADD"
      );
      break;
    case "pointage-form-edit":
      hasAccess = await fetchAccess(
        ["POINTAGES-EDIT", "POINTAGES-VALIDER-PERSO"],
        "POINTAGES-EDIT"
      );
      break;
    case "pointage-detail":
      hasAccess = await fetchAccess(["POINTAGES-EDIT", "POINTAGES-DELETE"]);
      break;

    case "project-intervenant-list":
      hasAccess = await fetchAccess(["PROJETS-WRITE"]);
      break;
    case "project-intervenant-new":
      hasAccess = await fetchAccess(["PROJETS-WRITE"], "PROJETS-WRITE");
      break;
    case "project-intervenant-detail":
      hasAccess = await fetchAccess(["PROJETS-WRITE"]);
      break;
    case "project-intervenant-fdc":
      hasAccess = await fetchAccess(["FIL-READ", "FIL-WRITE"], "FIL-READ");
      break;
    case "project-intervenant-fdc-join":
      hasAccess = await fetchAccess(["FIL-READ", "FIL-WRITE"], "FIL-READ");
      break;
    case "project-intervenant-contacts":
      hasAccess = await fetchAccess(["PROJETS-WRITE"]);
      break;
    case "project-intervenant-contacts-form":
      hasAccess = await fetchAccess(["PROJETS-WRITE"], "PROJETS-WRITE");
      break;
    case "project-detail":
      hasAccess = await fetchAccess([
        "PROJETS-WRITE",
        "PROJETS-DOCUMENTS-WRITE",
      ]);
      break;
    case "project-fdc":
      hasAccess = await fetchAccess(["FIL-READ", "FIL-WRITE"], "FIL-READ");
      break;
    case "project-fdc-join":
      hasAccess = await fetchAccess(["FIL-READ", "FIL-WRITE"], "FIL-READ");
      break;
    case "project-docs":
      hasAccess = await fetchAccess(
        ["PROJETS-DOCUMENTS-WRITE", "PROJETS-DOCUMENTS-READ"],
        "PROJETS-DOCUMENTS-READ"
      );
      break;
    case "project-notes":
      hasAccess = await fetchAccess(["PROJETS-WRITE"]);
      break;
    case "project-equipeinterne":
      hasAccess = await fetchAccess(["PROJETS-WRITE"]);
      break;
    case "project-equipeinterne-detail":
      hasAccess = await fetchAccess(["TACHES-COLLABO-READ"]);
      break;
    case "project-equipeinterne-tasks":
      hasAccess = await fetchAccess(
        ["TACHES-COLLABO-ADD", "TACHES-COLLABO-READ"],
        "TACHES-COLLABO-READ"
      );
      break;
    case "project-equipeinterne-tasks-details":
      hasAccess = await fetchAccess(
        ["TACHES-COLLABO-ADD", "TACHES-COLLABO-READ"],
        "TACHES-COLLABO-READ"
      );
      break;
    case "project-equipeinterne-tasks-fdc":
      hasAccess = await fetchAccess(["FIL-READ", "FIL-WRITE"], "FIL-READ");
      break;
    case "project-equipeinterne-tasks-fdc-join":
      hasAccess = await fetchAccess(["FIL-READ", "FIL-WRITE"], "FIL-READ");
      break;
    case "project-equipeinterne-tasks-details-documents":
      hasAccess = await fetchAccess([
        "TACHES-COLLABO-EDIT",
        "TACHES-PERSO-EDIT",
      ]);
      break;
    case "project-equipeinterne-tasks-details-edit":
      hasAccess = await fetchAccess(
        ["TACHES-COLLABO-ADD", "TACHES-COLLABO-READ"],
        "TACHES-COLLABO-ADD"
      );
      break;
    case "project-equipeinterne-tasks-new":
      hasAccess = await fetchAccess(
        ["TACHES-COLLABO-ADD", "TACHES-COLLABO-READ"],
        "TACHES-COLLABO-ADD"
      );
      break;
    case "project-tasks":
      hasAccess = await fetchAccess(
        ["TACHES-COLLABO-ADD", "TACHES-COLLABO-READ"],
        "TACHES-COLLABO-READ"
      );
      break;
    case "project-tasks-fdc":
      hasAccess = await fetchAccess(["FIL-READ", "FIL-WRITE"], "FIL-READ");
      break;
    case "project-tasks-fdc-join":
      hasAccess = await fetchAccess(["FIL-READ", "FIL-WRITE"], "FIL-READ");
      break;
    case "project-tasks-details":
      hasAccess = await fetchAccess(
        ["TACHES-COLLABO-ADD", "TACHES-COLLABO-READ"],
        "TACHES-COLLABO-READ"
      );
      break;
    case "project-tasks-details-documents":
      hasAccess = await fetchAccess([
        "TACHES-COLLABO-EDIT",
        "TACHES-PERSO-EDIT",
      ]);
      break;
    case "project-tasks-details-edit":
      hasAccess = await fetchAccess(
        ["TACHES-COLLABO-ADD", "TACHES-COLLABO-READ"],
        "TACHES-COLLABO-ADD"
      );
      break;
    case "project-tasks-new":
      hasAccess = await fetchAccess(
        ["TACHES-COLLABO-ADD", "TACHES-COLLABO-READ"],
        "TACHES-COLLABO-ADD"
      );
      break;

    case "tasks":
      hasAccess = await fetchAccess([
        "TACHES-PERSO-ADD",
        "TACHES-COLLABO-ADD",
        "TACHES-PERSO-READ",
        "TACHES-COLLABO-READ",
      ]);
      break;
    case "tasks-details":
      hasAccess = await fetchAccess([
        "TACHES-COLLABO-EDIT",
        "TACHES-PERSO-EDIT",
      ]);
      break;

    case "tasks-fdc":
      hasAccess = await fetchAccess(["FIL-READ", "FIL-WRITE"], "FIL-READ");
      break;
    case "tasks-fdc-join":
      hasAccess = await fetchAccess(["FIL-READ", "FIL-WRITE"], "FIL-READ");
      break;
    case "tasks-documents":
      hasAccess = await fetchAccess([
        "TACHES-COLLABO-EDIT",
        "TACHES-PERSO-EDIT",
      ]);
      break;
    case "tasks-edit":
      hasAccess = await fetchAccess([
        "TACHES-COLLABO-EDIT",
        "TACHES-PERSO-EDIT",
      ]);
      break;

    case "fdc":
      hasAccess = await fetchAccess(["FIL-READ", "FIL-WRITE"]);
      break;
    case "fdc-conversation":
      hasAccess = await fetchAccess(["FIL-READ", "FIL-WRITE"]);
      break;
  }
  if (isDev()) {
    console.info(route.name, hasAccess);
  }
  return { hasAccess };
}
