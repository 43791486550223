import { ref } from "vue";
import { IOptionListItem } from "../layout/default/components/SidebarRightOptions";

export enum LayoutEnum {
  empty = "empty-layout",
  default = "default-layout",
}

/**
 * Layout utiliser
 */
const currentLayout = ref<LayoutEnum>(LayoutEnum.empty);

/**
 * Toggle pour ouvrir/fermer le drawer s'il y en a un
 */
const showDrawer = ref<boolean>(false);

/**
 * Toggle pour autorisé l'ouverture du drawer avec le swipe
 */
const canSwipe = ref<boolean>(true);

/**
 * Toggle pour la navbar
 */
const showNavbar = ref<boolean>(true);


/**
 * Toggle pour la loader
 */
const showLoader = ref<boolean>(false);

const showBottomMenu = ref<boolean>(false);

const sidebarRightOptions = ref<IOptionListItem[]>();

export default function useLayout() {
  const toggleDrawer = () => (showDrawer.value = !showDrawer.value);

  const setLayout = (layout: LayoutEnum) => (currentLayout.value = layout);

  const setDrawer = (visible: boolean) => (showDrawer.value = visible);
  const setBottomMenu = (visible: boolean) => (showDrawer.value = visible);
  const setSwipe = (_canSwipe: boolean) => (canSwipe.value = _canSwipe);
  const setNavbar = (visible: boolean) => (showNavbar.value = visible);
  const setLoader = (visible: boolean) => (showLoader.value = visible);

  const setSidebarRightOptions = (value: IOptionListItem[]) => {
    if (value.length > 0) {
      sidebarRightOptions.value = value;
    } else {
      sidebarRightOptions.value = undefined;
    }
  };

  return {
    showNavbar,
    showDrawer,
    showLoader,
    sidebarRightOptions,

    canSwipe,

    currentLayout,

    setLayout,
    setNavbar,
    setSwipe,
    setDrawer,
    setLoader,
    setSidebarRightOptions,

    toggleDrawer,

    showBottomMenu,
    setBottomMenu,
  };
}
