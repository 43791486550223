import { MaybeRef } from "@vueuse/core";
import { ComponentPublicInstance, unref } from "vue";

/**
 * Pour merger les class CSS de tailwindCSS en un seul string
 * @param src 
 * @param classCssToConcat 
 * @returns 
 */
export function concatClassCSS(src: string, ...classCssToConcat: string[]): string {
    classCssToConcat.forEach(x => src += ` ${x}`)
    return src.trim();
}


/**
 * Indique si l'element est/ ou est présent dans le l'element source
 * @param el source
 * @param target
 * @returns 
 */
export function hasElement(el: HTMLElement, target: HTMLElement): boolean {
    if (el && target) {
        if (el === target) return true;
        const childs = el.querySelectorAll(target.tagName)
        for (let i = 0; i < childs.length; i++) {
            if (childs[i] == target) {
                return true;
            }
        }
    }
    return false;
}

/**
 * Indique si l'element est/ ou est présent dans le l'element source
 * @param id source id
 * @param target
 * @returns 
 */
export function hasElementById(id: string, target: HTMLElement): boolean {
    const el = document.getElementById(id)
    if (el && target) {
        if (el === target) return true;
        const childs = el.querySelectorAll(target.tagName)
        for (let i = 0; i < childs.length; i++) {
            if (childs[i] == target) {
                return true;
            }
        }
    }
    return false;
}

/**
 * Indique si l'iframe est cross-domain
 * @param iframe 
 * @returns 
 */
export function canAccessIframe(iframe: HTMLIFrameElement): boolean {
    try {
        return Boolean(iframe.contentDocument);
    }
    catch (e) {
        return false;
    }
}

export function sortByDomNode<T>(
    nodes: T[],
    resolveKey: (item: T) => HTMLElement | null = (i) => i as unknown as HTMLElement | null
): T[] {
    return nodes.slice().sort((aItem, zItem) => {
        const a = resolveKey(aItem)
        const z = resolveKey(zItem)

        if (a === null || z === null) return 0
        if (a === undefined || z === undefined) return 0

        const position = a.compareDocumentPosition(z)

        if (position & Node.DOCUMENT_POSITION_FOLLOWING) return -1
        if (position & Node.DOCUMENT_POSITION_PRECEDING) return 1
        return 0
    })
}

/**
 * Recupere le DOM a partir d'une reference à un element
 * @param ref 
 * @returns 
 */
export function dom<T extends Element | ComponentPublicInstance>(ref?: MaybeRef<T>) {
    return (ref as { $el?: T })?.$el ?? unref(ref);
}

/**
 * Recupere le 'document' de l'element, sinon retourne celui de la page
 * @param element 
 * @returns Document
 */
export function getOwnerDocument<T extends Element | MaybeRef<Element>>(
    element: T | null | undefined
): Document {
    if (element instanceof Node) return element.ownerDocument
    if (Object.prototype.hasOwnProperty.call(element, 'value')) {
        const domElement = dom(element)
        if (domElement) return domElement.ownerDocument
    }

    return document
}