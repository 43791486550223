import { IIntervenant } from "@/pages/bureau/@models/intervenants.model";
import { reactive, ref, watch } from "vue";
import { IContact } from "../@models/contacts.model";
import { IFiltresBIM } from "../store/modules/societes.module";
import { IntervenantModule } from "../store/modules/intervenants.module";

class DefaultClass {
  public intervenants: { [index: string]: IIntervenant } = reactive({});
  public contacts: { [index: string]: IContact } = reactive({});
  public filtresBIM: IFiltresBIM[] = reactive([]);

  constructor() {
    //
  }

  public get arrayOfIntervenants() {
    //en 1 ligne
    return Object.keys(this.intervenants).map((i) => this.intervenants[i]);
  }

  public get arrayOfIds() {
    const ids: number[] = [];
    Object.keys(this.intervenants).forEach((i) => {
      ids.push(this.intervenants[i].index);
    });
    return ids;
  }

  public removeIntervenant(index: number) {
    delete this.intervenants[index];
  }

  public clear() {
    this.intervenants = reactive({});
  }

  public async refreshInter(indexInter: number) {
    //remove inter from store
    this.removeIntervenant(indexInter);
    await IntervenantModule.fetchIntervenant(indexInter);

    if (this.intervenants[indexInter].hasLogo) {
      const logoData = await IntervenantModule.fetchIntervenantLogo(indexInter);
      if (logoData) {
        this.intervenants[indexInter].logo = logoData;
      }
    }
  }
}
const IntervenantsTemp = new DefaultClass();

export default reactive(IntervenantsTemp);

