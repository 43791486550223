import { IContact } from "@/pages/bureau/@models/contacts.model";
import {
  CorpsMetierFieldForm,
  IIntervenant,
  IIntervenantPartial,
  IPieceInter,
} from "@/pages/bureau/@models/intervenants.model";
import { IPagination } from "@/pages/bureau/@types/api";
import {
  IIntervenantForm,
  IIntervenantNewDoc,
  IntervenantsFilters,
} from "@/pages/bureau/store/modules/intervenants.module";
import axios from "./index";

const BASE_ENDPOINT = "BUREAU";

export const intervenantsService = {
  /**
   *  Recupere une page d'intervenant filtré
   * @param payload filtre de recherche + page
   * @returns
   */
  getPaginedIntervenants(payload?: IntervenantsFilters) {
    return axios.post<{
      intervenants: IIntervenantPartial[];
      info: {
        nbIntervenant: number;
      };
      pagination: IPagination;
    }>(`${BASE_ENDPOINT}/INTERVENANTS`, payload);
  },

  /**
   * Recupere un intervenant par son index
   * @param payload Index intervenant
   * @returns
   */
  getIntervenant(payload: number) {
    return axios.get<{
      intervenant: IIntervenant;
    }>(`${BASE_ENDPOINT}/INTERVENANT/${payload}`);
  },

  updateIntervenant(
    index,
    payload:
      | Partial<IIntervenant>
      | { estCorbeille: boolean }
      | { corpsMetier: CorpsMetierFieldForm[]; editCorpsMetier: boolean }
  ) {
    return axios.post<{
      results: any[];
    }>(`${BASE_ENDPOINT}/INTERVENANT/${index}`, payload);
  },

  /**
   * Recupere les contacts d'un intervenant par son index
   * @param payload Index intervenant
   * @returns
   */
  getIntervenantContacts(payload: number) {
    return axios.get<{
      contacts: IContact[];
    }>(`${BASE_ENDPOINT}/INTERVENANT/${payload}/CONTACTS`);
  },

  /**
   *
   * @param indexInter index de l'intervenant
   * @param index index du contact
   * @param payload data du formulaire de type IContact
   */
  updateContact(indexInter, index, payload: Partial<IContact>) {
    return axios.post(
      `${BASE_ENDPOINT}/INTERVENANT/${indexInter}/CONTACT/${index}`,
      payload
    );
  },

  /**
   *
   * @param indexInter index de l'intervenant
   * @param payload data du formulaire de type IContact
   */
  createContact(indexInter, payload: Partial<IContact>) {
    return axios.put<{ contact: IContact }>(
      `${BASE_ENDPOINT}/INTERVENANT/${indexInter}/CONTACT`,
      payload
    );
  },

  /**
   *
   * @param indexInter index de l'intervenant
   * @param index index du contact
   */
  deleteContact(indexInter, index) {
    return axios.delete<{
      results: any[];
    }>(`${BASE_ENDPOINT}/INTERVENANT/${indexInter}/CONTACT/${index}`);
  },

  getImageContact(indexInter, indexContact) {
    return axios.get(
      `${BASE_ENDPOINT}/INTERVENANT/${indexInter}/CONTACT/${indexContact}/LOGO`,
      {
        responseType: "blob",
      }
    );
  },

  /**
   * Recupere les documents d'un intervenant par son index
   * @param payload Index intervenant
   * @returns
   */
  getIntervenantDocuments(payload: number) {
    return axios.get<{
      documents: IPieceInter[];
    }>(`${BASE_ENDPOINT}/INTERVENANT/${payload}/DOCUMENTS`);
  },

  /**
   * Pour recuperer le logo d'un intervenant
   * @param index
   * @returns
   */
  getIntervenantLogo(payload, predicate?): Promise<Blob> {
    return axios.get(`${BASE_ENDPOINT}/INTERVENANT/${payload}/LOGO`, {
      responseType: "blob",
      onDownloadProgress: predicate,
    });
  },

  getIntervenantFormulaire() {
    return axios.get(`${BASE_ENDPOINT}/INTERVENANT/ENUMS`);
  },

  getIntervenantDocFormulaire(indexIntervenant: number) {
    return axios.get(`${BASE_ENDPOINT}/INTERVENANT/DOCUMENTS/ENUMS`);
  },
  /**
   *
   * @param payload data du formulaire de type IIntervenant
   */
  createIntervenant(payload: IIntervenantForm) {
    return axios.put<{
      results: any[];
    }>(`${BASE_ENDPOINT}/INTERVENANT`, payload);
  },

  /**
   *
   * @param indexIntervenant index de l'intervenant
   * @param payload data du formulaire de type IIntervenantNewDoc
   */
  createDocIntervenant({
    indexIntervenant,
    docIntervenant,
  }: {
    indexIntervenant: number;
    docIntervenant: IIntervenantNewDoc;
  }) {
    return axios.put<{
      results: any[];
    }>(
      `${BASE_ENDPOINT}/INTERVENANT/${indexIntervenant}/DOCUMENT`,
      docIntervenant
    );
  },
};
